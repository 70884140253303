import * as React from 'react';
import { Link } from 'gatsby';

// Create URL path for numeric pagination
const getPageNumberPath = (currentIndex) => {
  if (currentIndex === 0) {
    return '/blog/1';
  }
  return '/blog/' + (currentIndex + 1);
}

export const Pagination = ({ pageInfo }) => {
  if (!pageInfo) {
    return null;
  }
  const { currentPage, pageCount } = pageInfo;

  if (pageCount === 1) {
    return null;
  }

  // Create URL path for previous and next buttons
  const prevPagePath =
    currentPage - 1 === 1 ? '/blog/1' : '/blog/' + (currentPage - 1).toString();
  const nextPagePath = '/blog/' + (currentPage + 1).toString();

  // Check if page is first or last to disable previous and next buttons
  const prevClass = currentPage === 1 ? 'disabled' : 'enabled';
  const nextClass = currentPage === pageCount ? 'disabled' : 'enabled';

  return (
    <div className="pagination">
      <Link className={prevClass} to={prevPagePath} rel="prev">
        Previous
      </Link>
      {/*  Render numeric pagination  */}
      {Array.from({ length: pageCount }, (_, i) => {
        let numClass = 'pageNumber'
        if (currentPage === i + 1) {
          numClass = 'currentPage'
        }
        return (
          <Link to={getPageNumberPath(i)} className={numClass} key={i + 1}>
            {i + 1}
          </Link>
        )
      })}
      <Link className={nextClass} to={nextPagePath} rel="next">
        Next
      </Link>
    </div>
  );
};
