import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Bio from '../components/blog/bio';
import Layout from '../components/blog/layout';
import { Pagination } from '../utils/pagination';

// Function to retrieve a small preview of the post's text
const firstParagraph = (post) => {
  // // Find the first text slice of post's body
  const firstTextSlice = post.body.find((slice) => slice.slice_type === 'text');
  if (firstTextSlice != null) {
    // Set the character limit for the text we'll show in the homepage
    const textLimit = 300
    const text = RichText.asText(firstTextSlice.primary.text.richText)
    const limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      return (
        <p className="mt-6 text-gray-800">{`${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`}</p>
      )
    }
    // If it's shorter than the limit, just show it normally
    return <p className="mt-6 text-gray-800">{text}</p>;
  }
  return null;
}

const BlogIndex = ({ data, location }) => {
  if (!data) return null
  const posts = data.allPrismicBlogPost.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} pageTitle="Adminmate — Blog">
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} pageTitle="Adminmate — Blog">
      <Bio />
      <hr className="w-full bg-gray-100 my-12" style={{height: '1px'}} />
      <div className="container max-w-screen-lg mx-auto">
        <div className="w-5/6 md:w-4/5 mx-auto my-12">
          {posts.map((post, key) => {
            const title = post.data.title?.text;
            const isLast = posts.length === key + 1;
            return (
              <div key={post.id} className={`pb-8 mb-8 ${!isLast ? 'border-b' : ''}`}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <time className="uppercase text-xs text-gray-500 font-bold">{post.data.date}</time>
                    <h2 className="mt-1 text-2xl tracking-tight font-extrabold text-gray-900 sm:leading-none md:text-3xl">
                      <Link to={`/blog/${post.uid}`} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                  </header>
                  <section>
                    {firstParagraph(post.data)}
                    {/* <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                      className="mt-6 text-gray-800"
                    /> */}
                  </section>
                  <div className="mt-6">
                    <Link className="text-amblue hover:opacity-90 uppercase text-sm tracking-wide font-black content-link" to={`/blog/${post.uid}`}>
                      Continue reading →
                    </Link>
                  </div>
                </article>
              </div>
            )
          })}
          <Pagination pageInfo={data.allPrismicBlogPost.pageInfo} />
        </div>
      </div>
    </Layout>
  );
}

export default BlogIndex;

export const query = graphql`
  query MyQuery($limit: Int!, $skip: Int!) {
    allPrismicBlogPost(
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        _previewable
        uid
        id
        url
        data {
          title {
            text
          }
          date(formatString: "MMM D, YYYY")
          body {
            ... on PrismicBlogPostDataBodyText {
              id
              slice_label
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`;
